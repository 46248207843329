import React from 'react';
import Layout from '../components/Layout';

export default function blogPage() {
  return (
    <Layout>
      <p>Posts</p>

      <p>posts would go here</p>
      
    </Layout>
  );
}

